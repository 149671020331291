

import {
    Component,
    Prop,
    Vue,
} from 'vue-property-decorator';
import {
    ProjectGroup,
    DailyWageReport,
    DailyWageReportLine,
    Repair,
    User,
} from '@/clients/omb-api';
import Loading from '@/components/Loading.vue';
import InputButton from '@/components/input/InputButton.vue';
import InputSelect from '@/components/input/InputSelect.vue';
import AlertError from '@/components/AlertError.vue';
import clone from 'clone';
import InputDefault from '@/components/input/InputDefault.vue';
import moment from 'moment';
import Api from '@/assets/ts/Api';
import DailyWageReportTableLine from '@/components/reports/DailyWageReportTableLine.vue';
import InputTextarea from '@/components/input/InputTextarea.vue';
import ExportReportDialog from '@/components/dialogs/ExportReportDialog.vue';
import Uploader from '@/assets/ts/uploader';
import { ROLE } from '@/assets/ts/enum/role';
import BaseDialog from './BaseDialog.vue';

@Component({
    computed: {
        ROLE() {
            return ROLE;
        },
    },
    components: {
        ExportReportDialog,
        InputTextarea,
        DailyWageReportTableLine,
        InputDefault,
        AlertError,
        InputSelect,
        InputButton,
        Loading,
        BaseDialog,
    },
})
export default class EditDailyWageReportDialog extends Vue {

    @Prop({ default: null })
    private existing!: DailyWageReport | null;

    @Prop({ required: true })
    private parent!: ProjectGroup | Repair;

    @Prop({ required: true })
    private parentType!: 'project-group' | 'repair';

    @Prop({ required: true })
    private currentUser!: User;

    private ready = false;
    private report: DailyWageReport = {};
    private totalHours = 0;

    private showErrors: boolean = false;
    private errors: { [key: string]: string } = {};
    private generalError = false;
    private deleting: boolean = false;
    private submitting: boolean = false;

    private title = 'Tagelohnzettel';

    private lineToRemove?: number | null = null;
    private exportDialogVisible = false;
    private deleteDialogVisible = false;

    private autosaveTimeout?: any;
    private lastSave: Date | null = null;

    private uploader!: Uploader;

    private get lastSaveString(): string | undefined {
        if (!this.lastSave) return undefined;

        const hours = this.lastSave.getHours() < 10 ? `0${this.lastSave.getHours()}` : `${this.lastSave.getHours()}`;
        const minutes = this.lastSave.getMinutes() < 10 ? `0${this.lastSave.getMinutes()}` : `${this.lastSave.getMinutes()}`;

        return `${hours}:${minutes}`;
    }

    private async created() {
        this.uploader = await Uploader.create();

        if (this.existing) {
            this.report = clone(this.existing);
        }
        if (!this.report.id) {
            await this.setInitialValues();
        } else {
            this.correctDateTypes();
        }

        this.setTitle();
        this.setTotalHours();
        this.ready = true;
    }

    private setTitle(): void {
        if (this.report.fileId) {
            this.title = 'Tagelohnzettel';
        } else if (this.report.id) {
            this.title = 'Tagelohnzettel bearbeiten';
        } else {
            this.title = 'Neuen Tagelohnzettel erstellen';
        }
    }

    private setTotalHours(): void {
        let sum = 0;
        if (this.report.lines) {
            this.report.lines.forEach((line) => {
                const mo = parseFloat(`${line.monday || 0}`);
                const tu = parseFloat(`${line.tuesday || 0}`);
                const we = parseFloat(`${line.wednesday || 0}`);
                const th = parseFloat(`${line.thursday || 0}`);
                const fr = parseFloat(`${line.friday || 0}`);
                sum += mo + tu + we + th + fr;
            });
        }
        this.totalHours = parseFloat(`${sum.toFixed(2)}`);
    }

    private correctDateTypes() {
        this.report.start = this.report.start ? new Date(this.report.start) : undefined;
        this.report.end = this.report.end ? new Date(this.report.end) : undefined;
    }

    private async setInitialValues() {
        const now = moment()
            .hour(0)
            .minute(0)
            .second(0);

        this.report.name = this.parent.name;
        this.report.street = this.parent.street;
        this.report.city = this.parent.city;
        this.report.zipCode = this.parent.zipCode;
        this.report.start = now.isoWeekday(1)
            .toDate();
        this.report.end = now.isoWeekday(5)
            .toDate();

        this.$forceUpdate();
    }

    private close() {
        this.$emit('close');
    }

    private onLineInput(line: DailyWageReportLine) {
        this.report.lines = this.report.lines || [];
        this.report.lines = this.report.lines.map((l) => (l.id === line.id ? line : l));
        this.setTotalHours();
    }

    private async submit(close?: boolean, callback?: () => void) {
        if (!this.parent || !this.parent.id) return;

        this.submitting = true;
        this.showErrors = false;

        if (this.parentType === 'repair') {
            this.report.repairId = this.parent.id;
        } else if (this.parentType === 'project-group') {
            this.report.projectGroupId = this.parent.id;
        } else {
            return;
        }

        Api.omb.saveDailyWageReport(this.report)
            .then((report) => {
                this.lastSave = new Date();
                this.submitting = false;
                this.emitUpdate(report);
                if (callback) {
                    callback();
                } else if (close) {
                    this.close();
                }
            })
            .catch((e) => {
                console.error(e);
                this.submitting = false;
                this.generalError = true;
                this.showErrors = true;
            });
    }

    private addLine(): void {
        this.report.lines = this.report.lines || [];
        this.report.lines.push({
            id: this.getTempLineId(),
        });

        this.onReportChange(true);
        this.$forceUpdate();
    }

    private removeLine(id: number | null | undefined): void {
        this.lineToRemove = null;
        if (!this.report.lines) return;

        this.report.lines = this.report.lines.filter((l) => l.id !== id);
        this.onReportChange(true);
        this.$forceUpdate();
    }

    private getTempLineId(): number {
        let tempId = Math.floor(-999999999 * Math.random());
        // eslint-disable-next-line no-loop-func
        while (this.report.lines && this.report.lines.find((l) => l.id === tempId)) {
            tempId = Math.floor(-999999999 * Math.random());
        }

        return tempId;
    }

    private exportReport(): void {
        this.submit(false, () => {
            this.deleteDialogVisible = false;
            this.exportDialogVisible = true;
        });
    }

    private downloadReport(): void {
        if (!this.report.fileId) return;
        const name = `Tagelohnzettel_${this.report.name || '?'}_${this.report.number || '?'}`;
        this.uploader.download(this.report.fileId || '', 'application/pdf', name);
    }

    private showDeleteDialog(): void {
        this.exportDialogVisible = false;
        this.deleteDialogVisible = true;
    }

    private async deleteReport(): Promise<void> {
        this.deleting = true;
        this.showErrors = false;

        Api.omb.deleteDailyWageReport(this.report.id || 0)
            .then(() => {
                this.submitting = false;
                this.$emit('delete', this.report.id);
                this.close();
            })
            .catch((e) => {
                console.error(e);
                this.deleting = false;
                this.generalError = true;
                this.showErrors = true;
            });
    }

    private emitUpdate(toEmit?: DailyWageReport): void {
        this.report = toEmit || this.report;
        this.$emit('update', this.report);
    }

    private onReportChange(instantSave?: boolean) {
        if (!this.ready || this.report.fileId) return;
        clearTimeout(this.autosaveTimeout);
        this.autosaveTimeout = setTimeout(this.submit, instantSave ? 0 : 500);
    }

}
